import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardContent, Typography } from "@mui/material";
import React from "react";

export default function CardItem({ img, title, color, onClick }){
    return (
        <Card
            sx={{
                height: '120px',
                padding: '20px',
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                '&:hover': {
                    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.3)', // Elevated shadow on hover
                },
            }}  
            onClick={onClick}
        >
            <CardContent 
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <FontAwesomeIcon icon={img} color={color}/>

                <Typography sx={{
                    mt: 1,
                    textAlign: 'center',
                    fontSize: '12px',
                    fontFamily: '"Poppins", sans-serif',
                    color: '#333333',
                    fontWeight: 'bold'
                }}
                >
                    {title}
                </Typography>
            </CardContent>
        </Card>
    );
};