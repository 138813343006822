import React from "react";
import NavBar from "../components/NavBar";
import { Box } from "@mui/material";
import Introduction from "../components/Introduction";
import About from "../components/About";
import Highlights from "../components/Highlights";
import Testimonials from "../components/Testimonials";
import ContactForm from "../components/Contact";
import FAQ from "../components/FAQ";
import Footer from "../components/Footer";
import FloatingActionButton from "../components/fab";

const LandingPage = () =>{


    return (
        <Box>

          <NavBar/>

          <Introduction/>

          <About/>

          <Highlights/>

          <Testimonials/>

          <ContactForm/>

          <FAQ/>

          <Footer/>
          
          <FloatingActionButton whatsapp={true}/>
          
        </Box>
    )
}

export default LandingPage;