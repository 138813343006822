import React from "react";
import { Box, Button, TextField, Typography, Grid, Paper, Container } from '@mui/material';
import logo from '../images/logo.png';
import { useNavigate } from "react-router-dom";
import { useState} from "react";
import { UserAuth } from "../context/AuthContext";
import { ref, set} from 'firebase/database';
import { database } from '../firebase_config/firebase';
import { showToast } from "../components/toast";
import { ToastContainer } from "react-toastify";

const Login = () =>{
    const [userdata, setUserData] = useState({
        email : '',
        password : '',
    });

    const navigate = useNavigate();

    const {signIn} = UserAuth();
    const [currentIndex, setCurrentIndex] = useState(1);


   
    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData((prevUserData) => ({
          ...prevUserData,
          [name]: value,
        }));
      };


    const moveSlider = (index) => {
        setCurrentIndex(index);
    
        const textGroup = document.querySelector('.text-group');
        textGroup.style.transform = `translateY(${-(index - 1) * 2.2}rem)`;
    };

    const navigateTo = (path) => {
        navigate(path);
    };



    const handleSubmit = async (e) =>{
        e.preventDefault();
        try{
            if(userdata.email === ''){
                showToast('enter email address');
                return;
            }

            if(userdata.password == ''){
                showToast('enter password');
                return;
            }

            await signIn(userdata.email, userdata.password);
          
            navigate("/home")
        }catch(e){
            showToast(e.message, false);
        }
    }



    return(
        <Box 
            sx={{ 
                backgroundColor: "#0b146b", 
                minHeight: "100vh", 
                display: "flex", 
                alignItems: "center", 
                justifyContent: "center"
            }}
        >
        
        <Paper elevation={6} sx={{ p: 4, borderRadius: 4, width: {xs: "100%", md: "50%"},
                m: 2}}>
            <Box sx={{ textAlign: 'center',}}>
                <img src={logo} alt="QData" style={{ width: '27px' }} />
                <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', mt: 2 }}>
                    Sign Up
                </Typography>
                <Typography variant="body2" component="p" sx={{ mt: 1 }}>
                    Don't have an account? {' '}
                    <Box
                        component="span"
                        sx={{
                            color: '#1976d2', 
                            cursor: 'pointer',
                            textDecoration: 'none',
                            transition: 'all 0.3s',
                            '&:hover': {
                                textDecoration: 'underline', 
                                color: '#115293', 
                            },
                        }}
                        onClick={() => navigate('/register')}
                    >
                        Sign Up
                    </Box>
                </Typography>
            </Box>

            <form onSubmit={handleSubmit}>

                <TextField
                    fullWidth
                    label="Email"
                    type="email"
                    name="email"
                    value={userdata.email}
                    onChange={handleChange}
                    margin="normal"
                />
            
                <TextField
                    fullWidth
                    label="Password"
                    type="password"
                    name="password"
                    value={userdata.password}
                    onChange={handleChange}
                    margin="normal"
                />
               

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ mt: 3, py: 1.5, borderRadius: 2 }}
                >
                    Sign In
                </Button>
            </form>

            <Typography variant="body2" component="p" sx={{ mt: 2, textAlign: 'center' }}>
                Lost your password?{' '}
                <Box
                    component="span"
                    sx={{
                        color: '#1976d2', 
                        cursor: 'pointer',
                        textDecoration: 'none',
                        transition: 'all 0.3s',
                        '&:hover': {
                            textDecoration: 'underline', 
                            color: '#115293', 
                        },
                    }}
                    onClick={() => navigate('/reset-password')}
                >
                    Reset Now
                </Box>
            </Typography>
        </Paper>
        <ToastContainer/>
    </Box>
    )
}

export default Login;