import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function FAQ() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container
        id="faq"
        sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            marginTop: 8
        }}
    >
        <Typography
            variant="h5" 
            sx={{ 
                fontFamily: 'Fredoka One, sans-serif', 
                color: 'grey.800', 
                marginBottom: '20px',
            }}
        >
            Frequently Asked Questions
        </Typography>

        <Box sx={{ width: '100%' }}>
            <Accordion
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                >
                    <Typography fontFamily="Poppins, sans-serif" variant="body2">
                        What is the typical deployment time for my data collection link?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography
                        variant="body2"
                        gutterBottom
                        fontFamily="Roboto, sans-serif"
                        sx={{ maxWidth: { sm: '100%', md: '70%' } }}
                    >
                        Data collection links are usually deployed within one hour. However, in some cases, the deployment process may take up to six hours.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion
                expanded={expanded === 'panel2'}
                onChange={handleChange('panel2')}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2d-content"
                    id="panel2d-header"
                >
                    <Typography  fontFamily="Poppins, sans-serif" variant="body2">
                        How long will it take to receive my results after submitting data for analysis?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography
                        variant="body2"
                        gutterBottom
                        fontFamily="Roboto, sans-serif"
                        sx={{ maxWidth: { sm: '100%', md: '70%' } }}
                    >
                        The data analysis process typically takes 24 hours.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion
                expanded={expanded === 'panel3'}
                onChange={handleChange('panel3')}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3d-content"
                    id="panel3d-header"
                >
                    <Typography fontFamily="Poppins, sans-serif" variant="body2">
                        What is the purpose of the "Extra Message" input when creating a custom project?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography
                        variant="body2"
                        gutterBottom
                        fontFamily="Roboto, sans-serif"
                        sx={{ maxWidth: { sm: '100%', md: '70%' } }}
                    >
                        This optional field allows users to provide any additional information or specific instructions related to link creation or data analysis.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion
                expanded={expanded === 'panel4'}
                onChange={handleChange('panel4')}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4d-content"
                    id="panel4d-header"
                >
                    <Typography fontFamily="Poppins, sans-serif" variant="body2">
                        Can I modify my project or link after it has been created?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography
                        variant="body2"
                        gutterBottom
                        fontFamily="Roboto, sans-serif"
                        sx={{ maxWidth: { sm: '100%', md: '70%' } }}
                    >
                        Link creation is an automated process. Any significant modifications after the link has been generated may incur additional charges.
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </Box>

    </Container>
  );
}
export default FAQ;