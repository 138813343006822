import React from 'react';
import { 
    Box, 
    Typography, 
    TextField, 
    Button, 
    Paper, 
    Container,
    useTheme,
    useMediaQuery
} from '@mui/material';

const ContactForm = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Container 
            id='contact'
            sx={{
                marginTop: 8
            }}
        >

            <Typography
                variant="h5" 
                sx={{ 
                    fontFamily: 'Fredoka One, sans-serif', 
                    color: 'grey.800', 
                    marginBottom: '20px',
                    textAlign: 'center'
                }}
            >
                Get In Touch
            </Typography>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: isMobile ? 'flex-start' : 'center',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <form 
                    id="contact-form" 
                    action="https://formspree.io/f/xrgwegyz" 
                    method="post"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: isMobile ? '100%' : '50%', // Adjust width based on device
                    }}
                >
                    <TextField
                        variant="outlined"
                        name="full_name"
                        label="Name"
                        required
                        margin='normal'
                        sx={{
                            backgroundColor: '#f6f7f8',
                            color: '#727272',
                        }}
                    />
                    <TextField
                        variant="outlined"
                        name="email"
                        label="Email Address"
                        type="email"
                        required
                        margin="normal"
                        fullWidth
                        sx={{
                            backgroundColor: '#f6f7f8',
                            color: '#727272',
                        }}
                    />
                    <TextField
                        variant="outlined"
                        name="phone"
                        label="Phone Number"
                        type="tel"
                        required
                        margin="normal"
                        fullWidth
                        sx={{
                            backgroundColor: '#f6f7f8',
                            color: '#727272',
                        }}
                    />
                    <TextField
                        variant="outlined"
                        name="message"
                        label="Your Message..."
                        multiline
                        rows={4}
                        required
                        margin="normal"
                        fullWidth
                        sx={{
                            backgroundColor: '#f6f7f8',
                            color: '#727272',
                        }}
                    />
                    <Button 
                        type="submit" 
                        variant="contained" 
                        sx={{ 
                            marginTop: 2,
                            marginBottom: 2,
                            background: 'linear-gradient(90deg, #030d42, #007bff)',
                            width: '50%',
                            p: 2
                        }}
                    >
                        Send Message
                    </Button>
                </form>
            </Box>

          
    
        </Container>
    );
};

export default ContactForm;
