import React from "react";
import logo from '../images/logo.png';
import { useNavigate } from "react-router-dom";
import { useState} from "react";
import { UserAuth } from "../context/AuthContext";
import { ref, set} from 'firebase/database';
import { database } from '../firebase_config/firebase';
import { showToast } from "../components/toast";
import { ToastContainer } from "react-toastify";
import { Box, Button, TextField, Typography, Grid, Paper, Container } from '@mui/material';
import { width } from "@fortawesome/free-solid-svg-icons/fa0";

const Register = () =>{
    const [userdata, setUserData] = useState({
        full_name : '',
        email : '',
        phone_number : '',
        password : '',
        password_confirm : '',
    });

    const navigate = useNavigate();

    const {createUser} = UserAuth();
    const [currentIndex, setCurrentIndex] = useState(1);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData((prevUserData) => ({
          ...prevUserData,
          [name]: value,
        }));
      };

    const navigateTo = (path) => {
        navigate(path);
    };


    const addDataToDatabase = async (user_id) =>{
        const databaseRef = ref(database, 'Users/' + user_id + '/UserData');

        const ref_data = {
            "full_name" : userdata.full_name,
            "email" : userdata.email,
            "password" : userdata.password,
            "phone_number" : userdata.phone_number,
        }

        await set(databaseRef, ref_data);
    }



    const handleSubmit = async (e) =>{
        e.preventDefault();
        try{

            if(userdata.full_name === ''){
                showToast('enter full name');
                return;
            }

            if(userdata.email === ''){
                showToast('enter email address');
                return;
            }

            if(userdata.phone_number == ''){
                showToast('enter phone number');
                return;
            }

            if(userdata.password.length < 6){
                showToast('passwords should be at least 6 characters', false);
                return;
            }

            if (userdata.password !== userdata.password_confirm) {
                showToast('Passwords do not match', false);
                return;
              }

            const userCredential = await createUser(userdata.email, userdata.password);
            const user = userCredential.user;
          
                        
            await addDataToDatabase(user.uid);
            navigate("/home")
        }catch(e){
            showToast(e.message, false);
        }
    }



    return (
        <Box 
            sx={{ 
                backgroundColor: "#0b146b", 
                minHeight: "100vh", 
                display: "flex", 
                alignItems: "center", 
                justifyContent: "center"
            }}
        >
            
            <Paper elevation={6} sx={{ p: 4, borderRadius: 4, width: {xs: "100%", md: "50%"},
                    m: 2}}>
                <Box sx={{ textAlign: 'center',}}>
                    <img src={logo} alt="QData" style={{ width: '27px' }} />
                    <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', mt: 2 }}>
                        Sign Up
                    </Typography>
                  
                    <Typography variant="body2" component="p" sx={{ mt: 1 }}>
                        Already have an account?{' '}
                        <Box
                            component="span"
                            sx={{
                                color: '#1976d2', 
                                cursor: 'pointer',
                                textDecoration: 'none',
                                transition: 'all 0.3s',
                                '&:hover': {
                                    textDecoration: 'underline', 
                                    color: '#115293', 
                                },
                            }}
                            onClick={() => navigate('/login')}
                        >
                            Sign In
                        </Box>
                    </Typography>
                </Box>

                <form onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        label="Full Name"
                        name="full_name"
                        value={userdata.full_name}
                        onChange={handleChange}
                        margin="normal"
                    />
                    <TextField
                        fullWidth
                        label="Email"
                        type="email"
                        name="email"
                        value={userdata.email}
                        onChange={handleChange}
                        margin="normal"
                    />
                    <TextField
                        fullWidth
                        label="Phone Number"
                        type="number"
                        name="phone_number"
                        value={userdata.phone_number}
                        onChange={handleChange}
                        margin="normal"
                    />
                    <TextField
                        fullWidth
                        label="Password"
                        type="password"
                        name="password"
                        value={userdata.password}
                        onChange={handleChange}
                        margin="normal"
                    />
                    <TextField
                        fullWidth
                        label="Confirm Password"
                        type="password"
                        name="password_confirm"
                        value={userdata.password_confirm}
                        onChange={handleChange}
                        margin="normal"
                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ mt: 3, py: 1.5, borderRadius: 2 }}
                    >
                        Sign Up
                    </Button>
                </form>

                <Typography variant="body2" component="p" sx={{ mt: 2, textAlign: 'center' }}>
                        By signing up, I agree to the{' '}
                        <Box
                            component="span"
                            sx={{
                                color: '#1976d2', 
                                cursor: 'pointer',
                                textDecoration: 'none',
                                transition: 'all 0.3s',
                                '&:hover': {
                                    textDecoration: 'underline', 
                                    color: '#115293', 
                                },
                            }}
                            onClick={() => navigate('/terms-and-conditions')}
                        >
                           Terms of Service
                        </Box>
                </Typography>
            </Paper>
            <ToastContainer/>
        </Box>
    );
}

export default Register;