import React from "react";
import '../css/terms_and_conditions_design.css';
import NavBar from "../components/NavBar";

const TermsAndConditions = () =>{
    return(
        <div>
            <NavBar/>

            <div>
                <section className="main-hero-container"> 

                    <div className="main-hero">

                    <div className="main-hero-description ts-and-cs-hero-description">
                    
                    <h1>Terms and Conditions</h1>
                    <p>Terms and Conditions for Qdata Data Collection Services</p>

                    </div>

                    </div>

            

                </section>
            </div>

            <div>
                <section className="head-section">
                    <h2>Welcome to Qdata! </h2>
                    <p>These Terms and Conditions govern your use of our data collection services. By using our services, you agree to comply with and be bound by the following terms and conditions:</p>
                </section>
            </div>

            <div>
                <section className="body-section">

                    <div className="body-text">
                    <h3><span>&#8226; </span>Customization and Project Requests</h3>
                    <p>
                    For those requesting a data collection URL, please note that we do not support contacting the admin to modify the link after it has been created. If you require a more customized project, you can upload your own file and provide a detailed description of your requirements.
                    </p>
                    <p>
                    If you are providing an additional description for your project, it must be well-explained for our team to understand and effectively fulfill your requirements. Please ensure clarity and specificity in your project description.
                    </p>
                    </div>


                    <div className="body-text">
                    <h3><span>&#8226; </span>Pricing and Negotiations</h3>
                    <p>
                    We do not support negotiations on prices with the admin. The prices for our services are fixed and non-negotiable. If you have any questions regarding pricing, please refer to our pricing page or contact our customer support team for assistance.
                    </p>
                    </div>

                    <div className="body-text">
                    <h3><span>&#8226; </span>Plagiarism Check - Data Sharing</h3>
                    <p>
                    We strictly prohibit users from sharing data results obtained through our services with individuals who haven't utilized our app. To ensure compliance, 
                    each result will be attached with a unique barcode for proper verification and authenticity. 
                    Please refrain from sharing data results with non-Qdata users to maintain the integrity of our platform. 
                    </p>
                    </div>




                    <div className="body-text">
                    <h3><span>&#8226; </span> Data Collection and Analysis</h3>
                    <p>
                    For those requesting a data collection URL, please be aware that once you end your data collection and send it for analysis, it cannot be undone. We have implemented a confirmation message to ensure you are certain before proceeding with this action.
                    </p>
                    <p>
                    If you choose to delete any received data from the data management dashboard, please note that it cannot be recovered. Take caution when deleting data, as it will be permanently removed from our system. 
                    </p>
                    </div>




                    <div className="body-text">
                    <h3><span>&#8226; </span>Project Deletion and Administration</h3>
                    <p>
                    We reserve the right to delete a project weeks after it has been ended. This ensures the smooth operation and management of our platform. We recommend downloading any necessary data or analysis results before the specified deletion period.
                    </p>
                    </div>


                </section>

            </div>

            <div>
                <section className="conclusion-section">
                    <h2>Conclusion</h2>
                    <p>These Terms and Conditions outline the guidelines and expectations for using Qdata's data collection services. By using our services, you agree to adhere to these terms. We encourage you to review these terms periodically for any updates or changes. Should you have any questions or concerns, please reach out to our customer support team for assistance.
                    </p>
                    <p >Thank you for choosing Qdata! Let's embark on a seamless data collection journey together. </p>
                </section>
            </div>
        </div>
    )
}

export default TermsAndConditions;