import { DataUsageRounded, ShareRounded, TimelineRounded } from "@mui/icons-material";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";

const items = [
    {
      icon: <DataUsageRounded/>,
      title: 'Efficient Data Management',
      description:
        'Easily manage your data with just a few clicks from the app dashboard. View and delete received data in real-time, eliminating the need for manual processes and streamlining your workflow.',
    },
    {
      icon: <ShareRounded/>,
      title: 'Seamless Data Sharing',
      description:
        'Share data collection links effortlessly from anywhere—no more printed forms or tedious manual sharing. Collaborate efficiently, gather insights, and track responses through the interactive dashboard.',
    },
    {
      icon: <TimelineRounded />,
      title: 'Real-Time Progress Tracking',
      description:
        'Monitor your data collection progress at a glance. The new dashboard offers real-time data counts, empowering you to make informed, data-driven decisions for project success.',
    },
  ];

function Highlights(){
    return (
        <Box
            id='highlights'
            sx={{
                color: 'white',
                bgcolor: 'grey.900',
                marginTop: 8
              }}
        >
            <Container
                sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mt:2,
                    mb:2
                }}
            >
                <Typography
                    variant="h5"
                    sx={{
                        fontFamily: 'Fredoka One, sans-serif',
                        color: '#007bff',
                        textAlign: 'center',
                        mt:2
                    }}
                >
                    Unlock the Full Power of QData!
                </Typography>

                <Grid container spacing={2.5} mt={2}>
                    {
                        items.map((item, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <Stack
                                    direction="column"
                                    color="inherit"
                                    component="Card"
                                    spacing={1}
                                    useFlexGap
                                    sx={{
                                        p: 3,
                                        height: '100%',
                                        border: '1px solid',
                                        borderColor: 'grey.700',
                                        background: 'transparent',
                                        backgroundColor: 'grey.800',
                                        borderRadius: 2
                                    }}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Box sx={{ opacity: '50%', marginRight: 1 }}>{item.icon}</Box>
                                        <Typography fontWeight='medium' gutterBottom>{item.title}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant="body2" sx={{ color: 'grey.400' }}>{item.description}</Typography>
                                    </Box>
                                </Stack>
                            </Grid>
                        ))
                    }
                </Grid>

                <Typography
                    variant="body1"
                    sx={{
                        fontFamily: 'Roboto, sans-serif',
                        color: 'grey.400',
                        textAlign: 'center',
                        mt: 1,
                        mb: 2
                    }}
                >
                    and much more...
    </Typography>
            </Container>

        </Box>
    )
};

export default Highlights;