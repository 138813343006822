import { Container, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";

function About(){
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Container id='about' sx={{ marginTop: 8 }}>

            <Typography
                variant="h5" 
                sx={{ 
                    fontFamily: 'Fredoka One, sans-serif', 
                    color: 'grey.800', 
                    marginBottom: '20px'
                }}
            >
                About Us
            </Typography>

            <Typography
                sx={{
                    fontFamily: 'Roboto, sans-serif', 
                    fontSize: isMobile ? '1rem' : '1.2rem', 
                    color: 'text.secondary', 
                    lineHeight: 1.6
                }}
            >
                At QData, we provide innovative solutions for seamless research management projects, 
                empowering professionals and organizations to gather, analyze, and utilize data effectively. 
                Our platform integrates cutting-edge technology with user-friendly tools to simplify the complexities of research, 
                ensuring accuracy, efficiency, and productivity. Whether you're managing a small project or a large-scale study, 
                QData is your trusted partner for all your research needs.
            </Typography>
            
        </Container>
    );
};

export default About;