import React, { useState } from 'react';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import { ref, set } from 'firebase/database';
import { database } from '../firebase_config/firebase';
import { ToastContainer } from 'react-toastify';
import showToast from './toast';

const MyRatingComponent = ({ user_id, unique_id }) => {
  const [value, setValue] = useState(3);
  const [feedback, setFeedback] = useState('');
  const [open, setOpen] = useState(true);

  const handleRatingChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value);
  };

  const rate = async () => {
    const ratingData = {
      user_id: user_id,
      unique_id: unique_id,
      rating: value,
      feedback: feedback,
    };

    const ratingsRef = ref(database, 'Ratings/' + unique_id);
    await set(ratingsRef, ratingData);
    localStorage.setItem(unique_id, true);

    setOpen(false);
    showToast('Thanks For Your Feedback!', true);
  };

  return (
    <Box>
        <Dialog open={open} fullWidth maxWidth="xs">
        <DialogTitle style={{ textAlign: 'center', position: 'relative', fontSize: '18px' }}>
            <CancelIcon onClick={() => setOpen(false)} style={{ cursor: 'pointer', position: 'absolute', top: '8px', right: '8px' }} />
        </DialogTitle>
        <DialogContent>
            <Box display="flex" flexDirection="column" alignItems="center">
            <p style={{ fontSize: '12px', marginBottom: '15px' }}>Rate Your Experience With Us</p>
            <Rating
                name="simple-controlled"
                value={value}
                onChange={handleRatingChange}
                size="large"
                sx={{
                '& .MuiRating-iconFilled': {
                    color: 'orange',
                },
                '& .MuiRating-iconFocus': {
                    color: 'orange',
                },
                '& .MuiRating-iconHover': {
                    color: 'green',
                },
                }}
            />
            <TextField
                label="Feedback"
                multiline
                rows={2}
                variant="outlined"
                value={feedback}
                onChange={handleFeedbackChange}
                sx={{ marginTop: '15px', width: '100%' }}
            />
            </Box>
        </DialogContent>
        <Box textAlign="center" p={1}>
            <Button onClick={rate} variant="contained" style={{ backgroundColor: '#081247', color: '#fff' }}>
            Submit
            </Button>
        </Box>
        </Dialog>
        <ToastContainer/>
    </Box>
  );
};

export default MyRatingComponent;
