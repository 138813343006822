import React from "react";
import { useState, useEffect } from "react";
import { ref, set, get} from 'firebase/database';
import { database } from '../firebase_config/firebase';
import { UserAuth } from '../context/AuthContext';
import SideNav from "../components/side_nav";
import LoadingComponent from "../components/loading_backdrop";
import { ToastContainer } from "react-toastify";
import { showToast } from "../components/toast";
import { Box, Button, Container, TextField, Typography } from "@mui/material";

const Settings = () => {
    const { user, updatePassword } = UserAuth();
    const [isLoading, setIsLoading] = useState(true);
    const [userData, setUserData] = useState({
        full_name: '',
        email: '',
        phone_number: '',
        password: ''
    });
    const [passwordData, setPasswordData] = useState({
        password : '',
        new_password : '',
        new_password_confirm : ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPasswordData((prevPasswordData) => ({
          ...prevPasswordData,
          [name]: value,
        }));
    };

      
    useEffect(() => {
      let isMounted = true;
      const userRef = ref(database, 'Users/' + user.uid + '/UserData');
    
      const get_user_data = async () => {
        try {
          const snapshot = await get(userRef);
    
          if (snapshot.exists()) {
            const data = snapshot.val();
            const email_value = data.email;
            const full_name_value = data.full_name;
            const phone_number_value = data.phone_number;
            const password_value = data.password;
    
            if (isMounted) {
              setUserData((prevUserData) => ({
                ...prevUserData,
                email: email_value,
                full_name: full_name_value,
                phone_number: phone_number_value,
                password: password_value
              }));
            }
    
          } else {
            console.log("User not found");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        } finally {
          if (isMounted) {
            setIsLoading(false);
          }
        }
      };
    
      get_user_data();
    
      // Cleanup function
      return () => {
        isMounted = false;
      };
    
    }, [user.uid]);
    

    const update_password = async () =>{
        if(passwordData.password === ''){
            showToast('enter password');
            return;
        }
        if(passwordData.new_password === ''){
            showToast('enter password');
            return;
        }
        if(passwordData.new_password_confirm === ''){
            showToast('enter password');
            return;
        }
        if(passwordData.new_password_confirm !== passwordData.new_password){
            showToast('new passwords must match');
            return;
        }
        if(passwordData.password !== userData.password){
            showToast('password is not correct');
            return;
        }

        const user_data_ref = ref(database, 'Users/' + user.uid + '/UserData/password');
        await updatePassword(passwordData.password, passwordData.new_password)
        .then(() =>{
          set(user_data_ref, passwordData.new_password);
          showToast('password successfully updated', true);
        })
        .catch((error) => {
          showToast(error.message);
        })
    };

    return (
      <Container sx={{ display: 'flex', justifyContent: 'center'}}>
        {isLoading && <LoadingComponent />}
        <SideNav />

        <Box sx={{ p: 2, border: '1px solid #ddd', borderRadius: 2, mt: 8, mb: 2, width: {xs: "100%", md: "50%"}}}>
            <Typography variant="h5" gutterBottom sx={{ fontFamily: "'Poppins', sans-serif" }}>
                Account Details
            </Typography>

            <Box sx={{ mb: 2 }}>
                <TextField
                    fullWidth
                    label="Name"
                    variant="outlined"
                    value={userData.full_name}
                    InputProps={{ readOnly: true }}
                    sx={{ mb: 2, fontFamily: "'Poppins', sans-serif" }}
                />
                <TextField
                    fullWidth
                    label="Email Address"
                    variant="outlined"
                    value={userData.email}
                    InputProps={{ readOnly: true }}
                    sx={{ mb: 2, fontFamily: "'Poppins', sans-serif" }}
                />
                <TextField
                    fullWidth
                    label="Phone Number"
                    variant="outlined"
                    value={userData.phone_number}
                    InputProps={{ readOnly: true }}
                    sx={{ mb: 2, fontFamily: "'Poppins', sans-serif" }}
                />
                <TextField
                    fullWidth
                    label="User ID"
                    variant="outlined"
                    value={!isLoading && user.uid }
                    InputProps={{ readOnly: true }}
                    sx={{ mb: 2, fontFamily: "'Poppins', sans-serif" }}
                />
            </Box>

            <Typography variant="h5" gutterBottom sx={{fontFamily: "'Poppins', sans-serif" }}>
                Update Password
            </Typography>

            <Box sx={{ mb: 2 }}>
                <TextField
                    fullWidth
                    label="Current Password"
                    variant="outlined"
                    type="password"
                    name="password"
                    value={passwordData.password}
                    onChange={handleChange}
                    sx={{ mb: 2, fontFamily: "font-family: 'Poppins', sans-serif" }}
                />
                <TextField
                    fullWidth
                    label="New Password"
                    variant="outlined"
                    type="password"
                    name="new_password"
                    value={passwordData.new_password}
                    onChange={handleChange}
                    sx={{ mb: 2, fontFamily: "'Poppins', sans-serif" }}
                />
                <TextField
                    fullWidth
                    label="Confirm New Password"
                    variant="outlined"
                    type="password"
                    name="new_password_confirm"
                    value={passwordData.new_password_confirm}
                    onChange={handleChange}
                    sx={{ mb: 2, fontFamily: "'Poppins', sans-serif" }}
                />
            </Box>

            <Button 
                variant="contained"
                color="primary"
                onClick={update_password}
                sx={{ mt: 2, backgroundColor: "#081247"}}
            >
                Save Changes
            </Button>
        </Box>

      <ToastContainer />
      </Container>
    );
}

export default Settings;