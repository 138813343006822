import { Box, Button, Container, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useRef } from "react";
import desktopMockUp from '../images/desktop-mockup.png';
import mobileMockUp from '../images/mobile-mockup.png';
import 'aos/dist/aos.css';
import { useNavigate } from "react-router-dom";

function Introduction(){
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const navigate = useNavigate();

    const navigateTo = (path) => {
        navigate(path);
    };

    return (
        <Box
            id="home"
            sx={{
                marginTop: 7,
                background: 'linear-gradient(90deg, #030d42, #007bff)'
            }}
        >
            <Container sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
                <Box sx={{ mt: isMobile ? 4 : 0, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Typography
                        sx={{
                            fontFamily: 'Fredoka One, sans-serif',
                            fontSize: isMobile ? '2rem' : '3rem',
                            color: 'white',
                            lineHeight: 1,
                            textAlign: isMobile ? 'center' : ''
                        }}
                    >
                        Welcome To QData
                    </Typography>

                    <Typography
                        sx={{
                            fontFamily: 'Roboto, sans-serif',
                            fontSize: isMobile ? '1rem' : '1.5rem',
                            color: 'white',
                            mt: 1,
                            textAlign: isMobile ? 'center' : ''
                        }}
                    >
                        Your one-stop solution for seamless research management projects.
                    </Typography>

                    {!isMobile && 
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                mt:3
                            }}
                            gap={2}
                        >
                            <Button
                                sx={{
                                    backgroundColor: 'transparent',
                                    color: 'white',
                                    border: '2px solid white',
                                    borderRadius: '8px',
                                    padding: '8px 20px',     
                                    transition: 'background-color 0.3s, color 0.3s',
                                    '&:hover': {
                                        backgroundColor: 'white',
                                        color: 'black',  
                                    }
                                }}
                                onClick={() => navigateTo("/login")}
                            >
                                Sign In
                            </Button>

                            <Button
                                sx={{
                                    backgroundColor: 'transparent',
                                    color: 'white',
                                    border: '2px solid white',
                                    borderRadius: '8px',
                                    padding: '8px 20px',     
                                    transition: 'background-color 0.3s, color 0.3s',
                                    '&:hover': {
                                        backgroundColor: 'white',
                                        color: 'black',  
                                    }
                                }}
                                onClick={() => navigateTo("/register")}
                            >
                                Sign Up
                            </Button>
                        </Box>
                    }
                </Box>

                {isMobile ? (
                    <img 
                        src={desktopMockUp} 
                        alt="qdata-homepage"
                        style={{
                            height: '200px'
                        }}
                    />
                ) : (
                    <img 
                        src={mobileMockUp} 
                        alt="qdata-homepage"
                        style={{
                            height: '600px'
                        }}
                    />
                )}

                {isMobile && 
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: 'center',
                            m:2
                        }}
                        gap={2}
                    >
                        <Button
                            sx={{
                                backgroundColor: 'transparent',
                                color: 'white',
                                border: '2px solid white',
                                borderRadius: '8px',
                                padding: '8px 20px',     
                                transition: 'background-color 0.3s, color 0.3s',
                                '&:hover': {
                                    backgroundColor: 'white',
                                    color: 'black',  
                                }
                            }}
                            onClick={() => navigateTo("/login")}
                        >
                            Sign In
                        </Button>

                        <Button
                            sx={{
                                backgroundColor: 'transparent',
                                color: 'white',
                                border: '2px solid white',
                                borderRadius: '8px',
                                padding: '8px 20px',     
                                transition: 'background-color 0.3s, color 0.3s',
                                '&:hover': {
                                    backgroundColor: 'white',
                                    color: 'black',  
                                }
                            }}
                            onClick={() => navigateTo("/register")}
                        >
                            Sign Up
                        </Button>
                    </Box>
                }
        
                     
             </Container>
            
        </Box>
    );
}

export default Introduction;