import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Backdrop, Typography } from "@mui/material";

const LoadingComponent = ({ msg }) => {
  return (
    <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1, display: 'flex', alignItems: 'center' })}
        open={true}
      >
        <CircularProgress color="inherit" sx={{m:2}}/>
        {msg && <Typography sx={{m: 2}}>{msg}</Typography>}
    </Backdrop>

  );
};

export default LoadingComponent;
