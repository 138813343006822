import React from "react";
import { useState } from "react";
import showToast from "../components/toast";
import { ToastContainer } from "react-toastify";
import { UserAuth } from '../context/AuthContext';
import { Box, Button, Container, TextField, Typography } from "@mui/material";

const ResetPassword = () =>{
    const [userEmail, setUserEmail] = useState('');

    const handleEmailChange = (e) => {
        setUserEmail(e.target.value);
    };

    const {sendPasswordReset} = UserAuth();

    const reset_password = async () =>{
        if(userEmail === ''){
            showToast('enter email address');
            return;
        }

        await sendPasswordReset(userEmail)
            .then(() => {
                showToast("Password reset email sent successfully", true);
            })
            .catch((error) => {
                showToast( error.message);
            });

    }

    return(
        <Container sx={{ display: 'flex', justifyContent: 'center'}}>
       

        <Box sx={{ p: 2, border: '1px solid #ddd', borderRadius: 2, mt: 2, width: {xs: "100%", md: "50%"}}}>
        
            <Typography variant="h5" gutterBottom sx={{fontFamily: "'Poppins', sans-serif" }}>
                Reset Password
            </Typography>

            <Box>
                <TextField
                    fullWidth
                    label="Email Address"
                    variant="outlined"
                    value={userEmail}
                    sx={{ mb: 2, fontFamily: "'Poppins', sans-serif" }}
                    onChange={handleEmailChange}
                />
                </Box>

            <Button 
                variant="contained"
                color="primary"
                onClick={reset_password}
                sx={{ mt: 1, backgroundColor: "#081247"}}
            >
                Send Reset Link
            </Button>
        </Box>

      <ToastContainer />
      </Container>
    );
}

export default ResetPassword;