import React from 'react';
import Fab from '@mui/material/Fab';
import { faMessage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FloatingActionButton = ({ customColor, icon, onClick, whatsapp }) => {

  const whatsapp_chat = () => {
    const encodedPhoneNumber = encodeURIComponent(2347059148543);
    const messageLines = [
        'Hello Good Day',
        "I'm from QData website"
      ];
      
    const encodedMessage = messageLines.map(line => encodeURIComponent(line)).join('%0A');
    
    const whatsappLink = `https://api.whatsapp.com/send?phone=${encodedPhoneNumber}&text=${encodedMessage}`;
  
    // Open the WhatsApp chat link in a new window or tab
    window.open(whatsappLink, '_blank');
};

  return (
    <Fab
      style={{
        backgroundColor: whatsapp ? "#008000" : customColor,
        position: 'fixed',
        bottom: '16px',
        right: '16px',
        zIndex: 1000,
      }}
      aria-label="add"
      onClick={whatsapp ? whatsapp_chat : onClick}
    >
      <FontAwesomeIcon icon={whatsapp ? faMessage : icon } color='#fff'/>

    </Fab>
  );
};

export default FloatingActionButton;
