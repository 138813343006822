import { Box, Container, Grid, Rating, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from "react-slick";

const items = [
    {
        name: 'Priscilla Adeniyi',
        review: 'Very easy to use and navigate, great customer service and this has been really helpful with data collection.',
        rating: 5
    },
    {
        name: 'Joy Oduagbon',
        review: 'I love the app, very easy and understanding to use and very quick for analysis.',
        rating: 5
    },
    {
        name: 'Favour Onyinyechi',
        review: 'A wonderful app that made the whole process seamless and easy.',
        rating: 4
    },
    {
        name: 'Misan Jiringho',
        review: 'Very good!',
        rating: 3
    },
  ];

function Testimonials(){
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: isMobile ? 2000 : 3000,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    
    
    return (
        
        <Container
            id='testimonials'
            sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                marginTop: 8,
            }}
        >
            <Typography
                variant="h5"
                sx={{
                    fontFamily: 'Fredoka One, sans-serif',
                    color: 'grey.800', 
                }}
            >
                Testimonials
            </Typography>

            <Typography
                sx={{
                    fontFamily: 'Roboto, sans-serif', 
                    fontSize: '0.8rem', 
                    color: 'text.secondary', 
                    lineHeight: 1.6,
                    m:1
                }}
            >
               Discover why users love QData. Experience our commitment to efficiency, reliability, and exceptional support. 
               Join us today for quality and innovation!
            </Typography>

            <Slider {...settings}>
                {items.map((item, index) => (
                    <Box 
                        key={index} 
                        sx={{ 
                            padding: 1, 
                            width: { xs: '100%', sm: '300px' },
                            height: '200px',
                            display: 'flex', 
                            justifyContent: 'center' 
                        }}
                    >
                       <Stack
                            direction="column"
                            color="inherit"
                            component="Card"
                            spacing={1}
                            useFlexGap
                            sx={{
                                p:2,
                                width: '100%',
                                height: '100%',
                                border: '1px solid',
                                borderColor: 'grey.300',
                                backgroundColor: 'white',
                                borderRadius: 2,
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Typography 
                                fontFamily="Roboto, sans-serif" 
                                variant="h6" 
                                gutterBottom 
                            >
                                {item.name}
                            </Typography>
                            <Typography 
                                fontFamily="Poppins, sans-serif" 
                                variant="body2" 
                                sx={{ color: 'grey.600', lineHeight: 1.5,  }}
                            >
                                {item.review}
                            </Typography>
                            <Rating 
                                name="read-only" 
                                value={item.rating} 
                                readOnly 
                                sx={{ alignSelf: 'flex-end' }}
                            />
                        </Stack>

                    </Box>
                ))}
            </Slider>


        </Container>
    )
};

export default Testimonials