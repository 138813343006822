import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import { AuthContextProvider } from "./context/AuthContext";
import TermsAndConditions from "./pages/terms_and_conditions";
import Login from "./pages/login";
import Register from "./pages/register";
import Home from "./pages/home";
import ProtectedRoute from "./context/ProtectedRoute";
import CreateProject from "./pages/create";
import Regular from "./pages/regular";
import SensoryEvaluation from "./pages/sensory_evaluation";
import LabAnalysis from "./pages/lab_analysis";
import CustomizedProject from "./pages/customized_project";
import Econometrics from "./pages/econometrics";
import Dashboard from "./pages/dashboard";
import ManageData from "./pages/manage_data";
import Settings from "./pages/settings";
import ResetPassword from "./pages/reset_password";
import ErrorPage from "./pages/404";

function App() {
  return (
    <div style={{ fontFamily: 'Avenir' }}>
        <AuthContextProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/" element={<LandingPage />} />
              <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
              <Route
              path='/home'
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
            />
             <Route
              path='/create'
              element={
                <ProtectedRoute>
                  <CreateProject/>
                </ProtectedRoute>
              }
            />
            <Route
              path='/create/regular'
              element={
                <ProtectedRoute>
                  <Regular/>
                </ProtectedRoute>
              }
            />
             <Route
              path='/create/sensory-evaluation'
              element={
                <ProtectedRoute>
                  <SensoryEvaluation/>
                </ProtectedRoute>
              }
            />
            <Route
              path='/create/lab-analysis'
              element={
                <ProtectedRoute>
                  <LabAnalysis/>
                </ProtectedRoute>
              }
            />
             <Route
              path='/create/customized-project'
              element={
                <ProtectedRoute>
                  <CustomizedProject/>
                </ProtectedRoute>
              }
            />
            <Route
            path='/create/econometrics'
            element={
              <ProtectedRoute>
                <Econometrics/>
              </ProtectedRoute>
            }
            />

           <Route
            path='/dashboard/:project_unique_id'
            element={
              <ProtectedRoute>
                <Dashboard/>
              </ProtectedRoute>
            }
          />

          <Route
            path='/dashboard/manage/:project_unique_id'
            element={
              <ProtectedRoute>
                <ManageData/>
              </ProtectedRoute>
            }
          />  

          <Route
            path='/settings'
            element={
              <ProtectedRoute>
                <Settings/>
              </ProtectedRoute>
            }
          />  
          
          <Route path="*" element={<ErrorPage/>} />
            </Routes>
          </BrowserRouter>
      </AuthContextProvider>
    </div>
  );
}

export default App;
