import React, { useEffect, useState } from "react";
import { ref, set, get, remove, child, update} from 'firebase/database';
import { database } from '../firebase_config/firebase';
import { UserAuth } from "../context/AuthContext";
import {showToast} from "../components/toast";
import { ToastContainer } from "react-toastify";
import { useParams, useNavigate } from 'react-router-dom';
import ConfirmDialog from "../components/confirm_dialog";
import success_image from '../images/checked.png'
import Dialog from "../components/dialog";
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import LoadingComponent from "../components/loading_backdrop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCopy, faDatabase, faPaperPlane, faDownload, faTrash } from '@fortawesome/free-solid-svg-icons';
import MyRatingComponent from "../components/rating_component";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import CardItem from "../components/card-item";
import { AppBar, Box, Card, CardContent, Container, Divider, Toolbar, Typography } from "@mui/material";


const Dashboard = () =>{
    const { project_unique_id } = useParams();
    const { user } = UserAuth();
    const [project_data, setProjectData] = useState({
        email : '',
        first_name : '',
        last_name : '',
        phone_number : '',
        status : '',
        data_count : '',
        data_collection_url : '',
        project_file_url: '',
        unique_name : project_unique_id.slice(0, -6),
        project_name : '',
        project_extra_message : '',
        outstanding_debt : '',
        result_url : '',
    });

    
    const navigate = useNavigate();
    const [showProgressDialog, setShowProgressDialog] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isEndDialogOpen, setIsEndDialogOpen] = useState(false);
    const [paymentDialog, setPaymentDialog] = useState(false);
    const [showDialogForSentAnalysis, setShowDialogForSentAnalysis] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [rated, setRated] = useState(false);


    useEffect(() => {
      let isMounted = true;
      const usersRef = ref(database, 'Users/' + user.uid + '/Projects/' + project_unique_id);
      const data_ref = ref(database, 'Users/' + user.uid + '/Projects/' + project_unique_id + '/CollectedData');
    
      const get_project_data = async () => {
        try {
          const snapshot = await get(usersRef);
    
          if (snapshot.exists()) {
            const data = snapshot.val();
            const email_value = data.email;
            const first_name_value = data.first_name;
            const last_name_value = data.last_name;
            const phone_number_value = data.phone_number;
            const status_value = data.status;
            const project_file_url_value = data.project_file_url;
            const data_collection_url_value = data.data_collection_url;
            const project_name_value = data.project_name;
            const project_extra_message_value = data.project_extra_message;
            const result_url_value = data.result_url;
            const outstanding_debt_value = data.outstanding_debt;
    
            if (isMounted) {
              setProjectData((prevProjectData) => ({
                ...prevProjectData,
                email: email_value,
                first_name: first_name_value,
                last_name: last_name_value,
                phone_number: phone_number_value,
                status: status_value,
                data_collection_url: data_collection_url_value,
                project_file_url: project_file_url_value,
                project_name: project_name_value,
                project_extra_message: project_extra_message_value,
                result_url: result_url_value,
                outstanding_debt: outstanding_debt_value,
              }));

              get(data_ref).then((snapshot) => {
                // Initialize a counter
                let numChildren = 0;
              
                // Iterate through the children and count them
                snapshot.forEach(() => {
                  numChildren++;
                });

                setProjectData((prevProjectData) => ({
                  ...prevProjectData,
                  data_count: numChildren,
                }));
               
              }).catch((error) => {
              });


              setIsLoading(false);
            }
          } else {
            console.log("Project not found");
            if (isMounted) {
              setIsLoading(false);
            }
          }
        } catch (error) {
          if (isMounted) {
            showToast(error.message);
            setIsLoading(false);
          }
        }
      };
    
      get_project_data();
    
      // Cleanup function
      return () => {
        isMounted = false;
      };
    }, [user.uid, project_unique_id]);
    
    useEffect(() => {
      const storedValue = localStorage.getItem(project_unique_id);
      if (storedValue) {
        setRated(storedValue);
      }
    }, []);
      
    const copy_to_clipboard = (data_to_copy) =>{
      const textarea = document.createElement('textarea');
      textarea.value = data_to_copy;
      textarea.setAttribute('readonly', '');
      textarea.style.position = 'absolute';
      textarea.style.left = '-9999px';
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
      showToast("copied", true);
    }

  

    const handleOpenDialog = () => {
      if(project_data.status === 'Ended'){
        setIsDialogOpen(true);
      }else{
        showToast('project is still running', false);
      }
    };
  
    const handleCloseDialog = () => {
      setIsDialogOpen(false);
    };

    const handleProceed = () => {
      delete_project();    
    };

    const handleOpenEndDialog = () => {
      if(project_data.data_count > 0){
        if(project_data.outstanding_debt > 0){
          setPaymentDialog(true);
        }else if(project_data.status === 'Awaiting Result' || project_data.status === 'Ended')(
          showToast('unavailable')
        )
        else{
          setIsEndDialogOpen(true);
        }
      }
      else{
        showToast('please collect some data and try again', false);
      }
      
    };
  
    const handleCloseEndDialog = () => {
      setIsEndDialogOpen(false);
      setPaymentDialog(false);
    };



    const handleEndProceed = () => {
        send_for_analysis();
    };


    const manage_received_data = () =>{
      if(project_data.status === 'Awaiting Result' || project_data.status === 'In Review' || project_data.status === 'Ended'){
        showToast('unavailable');
      }else{
        navigate(`/dashboard/manage/${project_unique_id}`);
      }
    };

    const generateTransactionReference = () => {
      const timestamp = Date.now().toString();
      const random = Math.floor(Math.random() * 1000).toString().padStart(3, '0');
      return `REF-${timestamp}-${random}`;
    };

    const config = {
      public_key: 'FLWPUBK-0fa5d8390604bc50501e9b99817e663e-X',
      tx_ref: generateTransactionReference(),
      amount: project_data.outstanding_debt,
      currency: 'NGN',
      payment_options: 'card,bank,transfer,ussd',
      customer: {
      email: project_data.email,
      phone_number: project_data.phone_number,
      name: project_data.first_name,
      },
      customizations: {
      title: 'QData',
      description: `Payment completion for project ${project_data.project_name}`,
      logo: 'https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg',
      },
  };

    const handleFlutterPayment = useFlutterwave(config);

    const handleEndProceedPayment = () => {
      setPaymentDialog(false);
      try{
        handleFlutterPayment({
            callback: (response) => {
              setPaymentDialog(false);
              if(response.status === "successful"){
                reset_payment();
                window.location.reload();
              }
              closePaymentModal();
            },
            onClose: () => {},
          })
         
     }catch(e){
        console.log(e.message);
     }
    };

    const reset_payment = async () =>{
      try {
        const project_debt_ref = ref(database, 'Users/' + user.uid + '/Projects/' + project_unique_id + "/outstanding_debt");
        await set(project_debt_ref, 0);
        showToast("Payment Successful", true);
      } catch (error) {
        showToast(error.message);
      }
    };

    function getCurrentDateAsString() {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');
      const hours = String(currentDate.getHours()).padStart(2, '0');
      const minutes = String(currentDate.getMinutes()).padStart(2, '0');
      const seconds = String(currentDate.getSeconds()).padStart(2, '0');
    
      const dateString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    
      return dateString;
    }

    const send_for_analysis = async () =>{
      try{
        handleCloseEndDialog();
        setShowProgressDialog(true);
  
        const project_status_ref = ref(database, 'Users/' + user.uid + '/Projects/' + project_unique_id);
        const awaiting_result_ref = ref(database, 'AwaitingResults/' + project_unique_id);
  
        const project_data_json = {
          data_count: project_data.data_count,
          email: project_data.email,
          first_name: project_data.first_name,
          last_name: project_data.last_name,
          phone_number : project_data.phone_number,
          unique_name: project_data.unique_name,
          unique_id: project_unique_id,
          project_name: project_data.project_name,
          project_extra_message: project_data.project_extra_message,
          Date: getCurrentDateAsString(),
          user_id: user.uid,
      };

      const p_e = {
        status : "Awaiting Result",
        data_collection_url : "null"
      }
  
        await set(awaiting_result_ref, project_data_json);
        await update(project_status_ref, p_e);

        terminate_link(project_unique_id);
        
  
        
        const full_name = project_data.first_name + " " + project_data.last_name;
  
        const htmlTemplate = `
          <!DOCTYPE html>
          <html>
          <head>
              <title>Data Collection Ended</title>
              <style>
                  /* CSS styles for the email */
                  body {
                      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                      background-color: #f5f5f5;
                  }
                  .container {
                      max-width: 600px;
                      margin: 0 auto;
                      padding: 20px;
                      background-color: #ffffff;
                      border: 1px solid #dddddd;
                      border-radius: 4px;
                      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
                  }
                  .logo {
                      text-align: center;
                      margin-bottom: 20px;
                  }
                  .logo img {
                      max-width: 100px;
                  }
                  .content {
                      margin-bottom: 30px;
                      font-size: 16px;
                      line-height: 1.5;
                  }
                  .footer {
                      text-align: center;
                      font-size: 6px;
                      color: #777777;
                      margin-top: 20px;
                      padding-top: 20px;
                      border-top: 1px solid #dddddd;
                  }
                  .footer img {
                      max-width: 80px;
                  }
              </style>
          </head>
          <body>
              <div className="container">
                  <div className="logo">
                      <img src="https://qdataapp.web.app/images/logo.png" alt="Company Logo">
                  </div>
                  <div className="content">
                      <p style="font-family: 'Arial', sans-serif; font-size: 20px;">Hello ${full_name},</p>
                      <p style="font-family: 'Arial', sans-serif;">We are pleased to inform you that your data collection for project: ${project_unique_id} has ended successfully.</p>
                      <p style="font-family: 'Arial', sans-serif;">We have received ${project_data.data_count} data entries for analysis and will be working on processing them.</p>
                      <p style="font-family: 'Arial', sans-serif;">We will notify you via email as soon as your results are ready.</p>
                      <p style="font-family: 'Arial', sans-serif;">If you have any questions or need further assistance, please feel free to reply to this email.</p>
                  </div>
                  <div className="footer">
                      <img src="https://qdataapp.web.app/images/qdata.png" alt="QData Logo"><br>
                      QData | +2347059148543 | qdata.app@gmail.com
                  </div>
              </div>
          </body>
          </html>
          
        `
    
        const data = {
          recipient: project_data.email,
          subject: "Data Collection Ended",
          message: htmlTemplate
        };
        
        fetch('https://test-ma.onrender.com/send-email', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        })
          .then(response => response.text())
          .then(result => {
            console.log(result);
            // Display success message or perform other actions
          })
          .catch(error => {
            console.error(error);
            // Display error message or handle the error
          });
  
        setShowProgressDialog(false);
        setShowDialogForSentAnalysis(true);
    
      }catch(e){
        setShowProgressDialog(false);
        showToast(e.message, false);
      }
    };

    const terminate_link = (uniqueName) => {
      fetch('https://qdata.com.ng/projects/link_terminator.php/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `unique_name=${encodeURIComponent(uniqueName)}`,
      })
        .then(response => response.text()) // Don't do this if you want to use response.json() later
        .then(text => console.log(text))
        .catch(error => console.error('Error:', error));
   }
   
   
    const download_result = () =>{
      if(project_data.result_url !== undefined){
        const link = document.createElement('a');
        link.href = project_data.result_url;
        link.target = '_blank';
        link.download = project_data.result_url.substring(project_data.result_url.lastIndexOf('/') + 1);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }else{
        showToast('result not available yet', false);
      }
    };

    const close_sent_dialog = () =>{
      setShowDialogForSentAnalysis(false); 
        window.location.reload();
    }

    const delete_project = async () => {
      handleCloseDialog();
      setShowProgressDialog(true);
      const dataRef = ref(database, "Users/" + user.uid + "/Projects/" + project_unique_id);
      
      await remove(dataRef);

      const full_name = project_data.first_name + " " + project_data.last_name;
    
      const htmlTemplate = `
        <!DOCTYPE html>
        <html>
        <head>
            <title>Project Deleted</title>
            <style>
                /* CSS styles for the email */
                body {
                  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                  background-color: #f5f5f5;
              }
              .container {
                  max-width: 600px;
                  margin: 0 auto;
                  padding: 20px;
                  background-color: #ffffff;
                  border: 1px solid #dddddd;
                  border-radius: 4px;
                  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
              }
              .logo {
                  text-align: center;
                  margin-bottom: 20px;
              }
              .logo img {
                  max-width: 100px;
              }
              .content {
                  margin-bottom: 30px;
                  font-size: 16px;
                  line-height: 1.5;
              }
              .thank-you {
                  margin-top: 20px;
                  text-align: center;
                  font-size: 18px;
                  font-weight: bold;
                  color: #333333;
              }
              .footer {
                  text-align: center;
                  font-size: 6px;
                  color: #777777;
                  margin-top: 20px;
                  padding-top: 20px;
                  border-top: 1px solid #dddddd;
              }
              .footer img {
                  max-width: 80px;
              }
            </style>
        </head>
        <body>
            <div className="container">
                <div className="logo">
                    <img src="https://qdataapp.web.app/images/logo.png" alt="Company Logo">
                </div>
                <div className="content">
                    <p style="font-family: 'Arial', sans-serif; font-size: 20px;">Hello ${full_name},</p>
                    <p style="font-family: 'Arial', sans-serif;">We are writing to confirm that you have successfully deleted your project: ${project_data.unique_name}.</p>
                    <p style="font-family: 'Arial', sans-serif;">Thank you for using our services. We appreciate your support and hope to assist you again in the future.</p>
                </div>
                <div className="thank-you">
                    <p style="font-family: 'Arial', sans-serif;">Thank You!</p>
                </div>
                <div className="footer">
                    <img src="https://qdataapp.web.app/images/qdata.png" alt="QData Logo"><br>
                    QData | +2347059148543 | qdata.app@gmail.com
                </div>
            </div>
        </body>
        </html>
      `;
    
      const data = {
        recipient: project_data.email,
        subject: "Project Deleted",
        message: htmlTemplate
      };
      
      await fetch('https://test-ma.onrender.com/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
    
      setShowProgressDialog(false);
      navigate('/home')
    };


    return(
      <Box>


          {!isLoading && project_data.result_url !== undefined && !rated &&
            <div className="fab">
                <MyRatingComponent user_id={user.uid} unique_id={project_unique_id}/>
            </div>
          }

          {isLoading && <LoadingComponent/>}
          
       
          <AppBar
            position="fixed"
            sx={{
              top: 0,
              left: 0,
              width: '100%',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              backgroundColor: '#161617',
              zIndex: 999,
            }}
          >
            <Toolbar sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                variant="h6"
                sx={{
                  flex: 1,
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontFamily: 'Cambria, Cochin, Georgia, Times, "Times New Roman", serif',
                  maxWidth: '50%',
                }}
              >
                {project_data.unique_name}
              </Typography>
            </Toolbar>
          </AppBar>

          <Container sx={{ mt: {xs: 10, md: 13}}}>

            <Box sx={{ display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>

              <Card
                sx={{
                  borderTopLeftRadius: '10px',
                  borderTopRightRadius: '10px',
                  backgroundColor: '#081247',
                  width: {xs: '100%', md: '50%'}
                }}
              >
                <CardContent 
                  sx={{
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >

                  {
                    project_data.data_collection_url !== undefined &&
                    <Box>
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography
                          sx={{ 
                            color: "#FAED7A",
                            fontSize: '12px',
                            fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif"
                          }}
                        >
                          {project_data.data_count}
                        </Typography>

                        <Typography
                          sx={{ 
                            color: "#fff",
                            fontSize: '12px',
                            fontFamily: "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif"
                          }}
                        >
                          Received Data
                        </Typography>
                      </Box>

                      <Divider sx={{ borderColor: "#fff", mt: 2, mb: 2}}/>

                      <Box sx={{ display: 'flex', flexDirection: 'column',}}>

                        <Box sx={{ display: 'flex', flexDirection: 'row'}}>
                          
                          <Typography
                            sx={{ 
                              flexGrow: 1,
                              color: "#FAED7A",
                              fontSize: '12px',
                              textAlign: 'center',
                              fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif"
                            }}
                          >
                            {project_data.data_collection_url}
                          </Typography>

                          <FontAwesomeIcon icon={faCopy} color="#CCCCCC" onClick={() => copy_to_clipboard(project_data.data_collection_url)}/>

                        </Box>

                    
                        <Typography
                          sx={{ 
                            color: "#fff",
                            fontSize: '12px',
                            textAlign: 'center',
                            fontFamily: "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif"
                          }}
                        >
                          Data Collection Url
                        </Typography>
                      </Box>

                      <Divider sx={{ borderColor: "#fff", mt: 2, mb: 2}}/>

                    </Box>
                  }
                  <Box sx={{ display: 'flex', flexDirection: 'column',}}>

                    <Box sx={{ display: 'flex', flexDirection: 'row'}}>
                      
                      <Typography
                        sx={{ 
                          flexGrow: 1,
                          color: "#FAED7A",
                          fontSize: '12px',
                          textAlign: 'center',
                          fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif"
                        }}
                      >
                        {project_unique_id}
                      </Typography>

                      <FontAwesomeIcon icon={faCopy} color="#CCCCCC" onClick={() => copy_to_clipboard(project_unique_id)}/>

                    </Box>


                    <Typography
                      sx={{ 
                        color: "#fff",
                        fontSize: '12px',
                        textAlign: 'center',
                        fontFamily: "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif"
                      }}
                    >
                      Project ID
                    </Typography>
                  </Box>

                  <Divider sx={{ borderColor: "#fff", mt: 2, mb: 2}}/>

                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography
                      sx={{ 
                        color: "#FAED7A",
                        fontSize: '12px',
                        fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif"
                      }}
                    >
                      {project_data.status}
                    </Typography>

                    <Typography
                      sx={{ 
                        color: "#fff",
                        fontSize: '12px',
                        fontFamily: "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif"
                      }}
                    >
                      Status
                    </Typography>
                  </Box>

                </CardContent>

              </Card>

              
              
                {!isLoading && project_data.data_count !== undefined ? (
                  <Grid2 container spacing={2} sx={{ p: {md:2}, mt: {xs: 2}}}>
                    <Grid2 item xs={6} md={6}>
                      <CardItem img={faDatabase} title="Manage Received Data" color="#6da5ee" onClick={manage_received_data}/>
                    </Grid2>

                    <Grid2 item xs={6} md={6}>
                      <CardItem img={faPaperPlane} title="Send For Analysis" color="#308a19" onClick={handleOpenEndDialog}/>
                    </Grid2>

                    <Grid2 item xs={6} md={6}>
                      <CardItem img={faDownload} title="Download Result" color="#FF8C00" onClick={download_result}/>
                    </Grid2>

                    <Grid2 item xs={6} md={6}>
                      <CardItem img={faTrash} title="Delete Project" color="#8B0000" onClick={handleOpenDialog}/>
                    </Grid2>
                  
                  </Grid2>
                ) : (
                  <Grid2 container spacing={2} sx={{ p: {md:2}, mt: {xs: 2}}}>
                    <Grid2 item xs={6} md={6}>
                      <CardItem img={faDownload} title="Download Result" color="#FF8C00" onClick={download_result}/>
                    </Grid2>

                    <Grid2 item xs={6} md={6}>
                      <CardItem img={faTrash} title="Delete Project" color="#8B0000" onClick={handleOpenDialog}/>
                    </Grid2>
                  
                  </Grid2>
                )  
              }

            
            </Box>
          </Container>


          <ToastContainer/>
          {showProgressDialog && <LoadingComponent msg="creating project"/>}

          <React.Fragment>
            <ConfirmDialog
                open={isDialogOpen}
                onClose={handleCloseDialog}
                onProceed={handleProceed}
                description={"By clicking \"Delete\", you confirm that you've download and confirmed your result"}/>
          </React.Fragment>

          <React.Fragment>
            <ConfirmDialog
              open={isEndDialogOpen}
              onClose={handleCloseEndDialog}
              onProceed={handleEndProceed}
              description={`We will now proceed to running your analysis with received data count as ${project_data.data_count} we will contact you via 
              ${project_data.phone_number} or ${project_data.email} if we encounter any problem running this and lastly, you will be notified via email
              when the result is ready`}/>
          </React.Fragment>

          <React.Fragment>
            <ConfirmDialog
              open={paymentDialog}
              onClose={handleCloseEndDialog}
              onProceed={handleEndProceedPayment}
              description={`You have an outstanding debt of ₦${project_data.outstanding_debt} pay off to continue with this process`}/>
          </React.Fragment>

          {showDialogForSentAnalysis && (
            <Dialog 
              imageUrl={success_image}
              message="Data sent for analysis"
              buttonText="Dismiss"
              onClose={close_sent_dialog}
            />              
          )}

        
              
        </Box>
    );
}

export default Dashboard;