import React, { useState } from 'react';
import { Box, Drawer, AppBar, Toolbar, Typography, IconButton, List, ListItem, ListItemIcon, ListItemText, CssBaseline, ListItemButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import FolderIcon from '@mui/icons-material/Folder';
import AddIcon from '@mui/icons-material/Add';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';
import { showToast } from '../components/toast';
import { ToastContainer } from "react-toastify";

const SideNav = () => {
  const [open, setOpen] = useState(false);
  const { logout } = UserAuth();
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setOpen(!open);
  };

  const logoutUser = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      showToast(error.message);
    }
  };

  const navigateTo = (path) => {
    navigate(path);
  };

  return (
    <Box>

        <AppBar position="fixed" open={open} sx={{ height: "50px", backgroundColor: "#0b146b", justifyContent: 'center'}}>
            <Toolbar sx={{ display: 'flex', ml: 1}}>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={toggleSidebar}
                    edge="start"
                    sx={{ width: '15px', mr: 1.5, }}
                >
                    <MenuIcon />
                </IconButton>
                
                <Typography sx={{ p: 2, fontSize: "22px", fontWeight: '500', fontFamily: '"Poppins", sans-serif' }}>QData</Typography>
            </Toolbar>
        </AppBar>


        <Drawer
            variant="temporary"
            anchor="left"
            open={open}
            onClose={toggleSidebar}
            ModalProps={{
            keepMounted: true,
            }}
            sx={{
            width: 240,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
                width: 240,
                boxSizing: 'border-box',
            },
            }}
        >
        <Box sx={{ display: 'flex', flexDirection: 'row', m: 2}}>
            <IconButton sx={{ width: '22px', p: 2}} onClick={toggleSidebar}>
                <MenuIcon sx={{ color: "#333" }}/>
            </IconButton>

            <Typography sx={{ p: 2, fontSize: "22px", fontWeight: '500', fontFamily: '"Poppins", sans-serif' }}>QData</Typography>
        </Box>
        <Box sx={{p: 0.5, display: 'flex', flexDirection: 'column', height: '100%'}}>
          <List sx={{ flexGrow: 1 }}>
            <ListItemButton  onClick={() => navigateTo('/home')}>
              <ListItemIcon>
                <FolderIcon />
              </ListItemIcon>
              <ListItemText primary="Projects" />
            </ListItemButton>

            <ListItemButton onClick={() => navigateTo('/create')}>
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText primary="New" />
            </ListItemButton>
         </List>

         <List>
            <ListItemButton onClick={() => navigateTo('/settings')}>
                <ListItemIcon>
                    <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Settings" />
                </ListItemButton>

                <ListItemButton  onClick={logoutUser}>
                <ListItemIcon>
                    <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary="Logout" />
                </ListItemButton>
         </List>
        </Box>
      </Drawer> 

      <ToastContainer />
    </Box>
  );
};

export default SideNav;
