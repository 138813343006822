import React from "react";
import { useParams } from 'react-router-dom';
import { ref, set, get, remove, query} from 'firebase/database';
import { database } from '../firebase_config/firebase';
import { UserAuth } from '../context/AuthContext';
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle, faDownload, faTrash  } from '@fortawesome/free-solid-svg-icons'
import showToast from "../components/toast";
import { ToastContainer } from "react-toastify";
import FloatingActionButton from "../components/fab";
import LoadingComponent from "../components/loading_backdrop";
import { Box, Card, CardContent, Container, Typography } from "@mui/material";
import CardItem from "../components/card-item";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";


const ManageData = () =>{
    const { project_unique_id } = useParams();
    const { user } = UserAuth();
    const [collected_data, setCollectedData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        let isMounted = true;
      
        const dta_ref = ref(database, 'Users/' + user.uid + '/Projects/' + project_unique_id + "/CollectedData");
      
        const get_projects = async () => {
          try {
            const snapshot = await get(query(dta_ref));
      
            if (!snapshot.exists()) {
              // Database reference is empty
              if (isMounted) {
                setCollectedData([]);
                setIsLoading(false);
              }
              return;
            }
      
            const projectsArray = [];
      
            snapshot.forEach((childSnapshot) => {
              const child_name = childSnapshot.key;
              const time = childSnapshot.child("timestamp").val();
      
              projectsArray.push({
                child_name: child_name,
                time: time,
              });
            });
      
            if (isMounted) {
              setCollectedData(projectsArray);
              setIsLoading(false);
            }
      
          } catch (error) {
            if (isMounted) {
              showToast(error.message, false);
              setIsLoading(false);
            }
          }
        };
      
        get_projects();
      
        // Cleanup function
        return () => {
          isMounted = false;
        };
      
      }, [user.uid, collected_data]);

    console.log(isLoading)

    const removeFromCollectedData = (keyToRemove) => {
        setCollectedData((prevCollectedData) =>
          prevCollectedData.filter((item) => item.child_name !== keyToRemove)
        );
      };

    const delete_data = async (child_name) =>{
        try {
            const data_ref = ref(database, 'Users/' + user.uid + '/Projects/' + project_unique_id + "/CollectedData/" + child_name);
            const count_ref = ref(database, 'Users/' + user.uid + '/Projects/' + project_unique_id + "/data_count");

            const currentCountSnapshot = await get(count_ref);
            const currentCount = currentCountSnapshot.val();

            await remove(data_ref);
            await set(count_ref, currentCount - 1);

            removeFromCollectedData(child_name);


            showToast('deleted', true)
        } catch (error) {
            showToast(error.message);
        }
    };

    async function downloadCSVFromFirebase() {
        const dtaRef = ref(database, `Users/${user.uid}/Projects/${project_unique_id}/CollectedData`);
      
        try {
          const snapshot = await get(dtaRef);
          const data = snapshot.val();
      
          // Check if data is empty or undefined
          if (data) {
            // Assuming data is an object with nested objects
            const flattenedData = flattenNestedObjects(data);
            downloadCSV(flattenedData, `QDATA(${project_unique_id}).csv`);
          } else {
            showToast('no data found');
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    }
      
    // Function to flatten nested objects
    function flattenNestedObjects(data) {
      const flattenedData = [];
    
      // Iterate through each key in the data object
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          // Flatten each nested object and add it to the flattenedData array
          const flatObject = flattenObject(data[key]);
          flattenedData.push(flatObject);
        }
      }
    
      return flattenedData;
    }
    
    // Helper function to flatten a nested object
    function flattenObject(obj, prefix = '') {
      const flatObject = {};
    
      // Iterate through each key in the object
      for (const key in obj) {
        if (Object.hasOwnProperty.call(obj, key)) {
          // Create a new key by combining the prefix and the current key
          const newKey = prefix ? `${prefix}.${key}` : key;
    
          if (typeof obj[key] === 'object' && obj[key] !== null) {
            // If the value is an object, recursively flatten it
            Object.assign(flatObject, flattenObject(obj[key], newKey));
          } else {
            // If the value is not an object, assign it to the flatObject
            flatObject[newKey] = obj[key];
          }
        }
      }
    
      return flatObject;
    }
    
    // Function to convert data to CSV format
    function convertToCSV(data) {
      if (!data || data.length === 0) {
        return ''; // Return an empty string or some default value
      }
    
      const header = Object.keys(data[0]).join(',');
      const csv = [header];
    
      data.forEach(row => {
        const rowValues = Object.values(row).map(value => `"${value}"`);
        csv.push(rowValues.join(','));
      });
    
      return csv.join('\n');
    }
    
    // Function to download CSV
    function downloadCSV(data, fileName) {
      const csvContent = convertToCSV(data);
      const blob = new Blob([csvContent], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
    
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }
      
    return(
        <Container>
             <ToastContainer/>

            {isLoading && <LoadingComponent/>}

            {!isLoading && collected_data.length >= 0 && 
                <FloatingActionButton customColor="#081247" icon={faDownload} onClick={downloadCSVFromFirebase}/>
            }

             {!isLoading && collected_data.length <= 0 ? (
              
                <Box sx={{mt: 2}}>
                  <CardItem img={faExclamationCircle} title="No Data Collected" />  
                </Box>

                ) : (
                <Grid2 container sx={{ mt: 2 }} spacing={2}>
                {collected_data
                  .slice()
                  .sort((a, b) => new Date(b.time) - new Date(a.time))
                  .map((data, index) => (
                    <Grid2 item xs={12} md={6} key={index}>
                      <Card
                        onClick={() => delete_data(data.child_name)}
                        sx={{
                          borderRadius: '8px',
                          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                          transition: 'box-shadow 0.3s ease, transform 0.3s ease',
                          '&:hover': {
                            boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
                            transform: 'translateY(-2px)',
                          },
                        }}
                      >
                        <CardContent sx={{ display: 'flex', flexDirection: 'row' }}>
                          <Box sx={{ flexGrow: 1 }}>
                            <Typography
                              sx={{
                                fontFamily: "'Courier New', Courier, monospace",
                                color: '#333333',
                                fontSize: '13px',
                              }}
                            >
                              {`${data.child_name} ${data.time}`}
                            </Typography>
                          </Box>
                          <FontAwesomeIcon
                            icon={faTrash}
                            color="#8B0000"
                            style={{ fontSize: '20px' }}
                          />
                        </CardContent>
                      </Card>
                    </Grid2>
                  ))}
                </Grid2>
              )}

        </Container>
    );
}
export default ManageData;