import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Link, List, ListItem } from "@mui/material";

const Footer = () => {
  const navigate = useNavigate();

  const navigateTo = (path) => {
    navigate(path);
  };

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: 'grey.900',
        color: 'grey.400',
        padding: 3,
        textAlign: 'center',
        borderTop: '1px solid #e0e0e0',
        marginTop: 8
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <List sx={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
          <ListItem sx={{ padding: 1 }}>
            <Link href="#contact" underline="none" sx={{ color: 'inherit' }}>
              Contact us
            </Link>
          </ListItem>
          <ListItem sx={{ padding: 1 }}>
            <Link href="#" underline="none" sx={{ color: 'inherit' }}>
              Our Services
            </Link>
          </ListItem>
          <ListItem sx={{ padding: 1 }} onClick={() => navigateTo('/terms-and-conditions')}>
            <Link href="#" underline="none" sx={{ color: 'inherit' }}>
              Terms & Conditions
            </Link>
          </ListItem>
        </List>
      </Box>

      <Typography variant="body2" sx={{ marginTop: 2 }}>
        QDATA Copyright © 2022 - All rights reserved
      </Typography>
    </Box>
  );
};

export default Footer;
